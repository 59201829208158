"use client";
import React, { ReactNode } from "react";
import { motion } from "framer-motion";
import {
  HomeIcon,
  LightningBoltIcon,
} from "@radix-ui/react-icons";

const RibbonMarquee = () => {
  return (
    <section className="pt-10 pb-32 w-full overflow-hidden mx-auto">
      <h2 className="mx-4 mb-12 text-center text-2xl font-bold text-brand-blue-light md:text-2xl">
        1B + requests tracked for users like...
      </h2>
      <div className="flex translate-y-[50%] rotate-[7deg] scale-110 overflow-hidden bg-brand-beige shadow-lg">
        <TranslateWrapper>
          <LogoItemsTop />
        </TranslateWrapper>
        <TranslateWrapper >
          <LogoItemsTop />
        </TranslateWrapper>
        <TranslateWrapper >
          <LogoItemsTop />
        </TranslateWrapper>
      </div>
      <div className="flex -translate-y-[50%] -rotate-[7deg] scale-110 overflow-hidden bg-brand-beige shadow-lg">
        <TranslateWrapper reverse>
          <LogoItemsBottom />
        </TranslateWrapper>
        <TranslateWrapper reverse>
          <LogoItemsBottom />
        </TranslateWrapper>
        <TranslateWrapper reverse>
          <LogoItemsBottom />
        </TranslateWrapper>
      </div>
    </section>
  );
};


const TranslateWrapper = ({
  children,
  reverse,
}: {
  children: ReactNode;
  reverse?: boolean;
}) => {
  return (
    <motion.div
      initial={{ translateX: reverse ? "-100%" : "0%" }
      }
      animate={{ translateX: reverse ? "0%" : "-100%" }}
      transition={{ duration: 50, repeat: Infinity, ease: "linear" }}
      className="flex px-2"
    >
      {children}
    </motion.div>
  );
};

const LogoItem = ({ Icon, name }: { Icon: React.ElementType; name: string; }) => {
  return (
    <div
      className="flex items-center justify-center gap-4 px-4 py-4 text-brand-blue-reg transition-colors  pointer-events-none"
    >
      <Icon className="text-3xl md:text-4xl" />
      <span className="whitespace-nowrap text-2xl font-bold uppercase md:text-2xl" >
        {name}
      </span>
    </div>
  );
};

const LogoItemsTop = () => (
  <>
    <LogoItem Icon={HomeIcon} name="Nike" />
    <LogoItem Icon={HomeIcon} name="3M" />
    <LogoItem Icon={HomeIcon} name="Abstract" />
    <LogoItem Icon={HomeIcon} name="Adobe" />
    <LogoItem Icon={HomeIcon} name="Airtable" />
    <LogoItem Icon={HomeIcon} name="Amazon" />
    <LogoItem Icon={HomeIcon} name="Box" />
    <LogoItem Icon={HomeIcon} name="Bytedance" />
    <LogoItem Icon={HomeIcon} name="Chase" />
    <LogoItem Icon={HomeIcon} name="Cloudebees" />
  </>
);

const LogoItemsBottom = () => (
  <>
    <LogoItem Icon={LightningBoltIcon} name="BMW" />
    <LogoItem Icon={LightningBoltIcon} name="Texto" />
    <LogoItem Icon={LightningBoltIcon} name="Buildkite" />
    <LogoItem Icon={LightningBoltIcon} name="Couchbase" />
    <LogoItem Icon={LightningBoltIcon} name="Dailymotion" />
    <LogoItem Icon={LightningBoltIcon} name="deliveroo" />
    <LogoItem Icon={LightningBoltIcon} name="Epic Games" />
    <LogoItem Icon={LightningBoltIcon} name="Genius" />
    <LogoItem Icon={LightningBoltIcon} name="GoDaddy" />
    <LogoItem Icon={LightningBoltIcon} name="Heroku" />
  </>
);

export default RibbonMarquee;