const GlowingChip = ({ children }: { children: any }) => {
  return (
    <span
      className="relative z-10 mb-4 inline-block rounded-full border border-brand-blue-light bg-brand--blue-light/20 px-3 py-1.5 text-sm text-zinc-50 md:mb-0">
      {children}
      <span
        className="absolute bottom-0 left-3 right-3 h-[1px] bg-gradient-to-r from-zinc-500/0 via-zinc-300 to-zinc-500/0"
      />
    </span>
  );
};

export default GlowingChip;