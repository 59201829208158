"use client";
import { Link } from "@/navigation";
import React from "react";

export default function HomeServices() {
  const services = [
    {
      id: "web-development",
      icon: "/images/services/services-web.png",
      title: "Desarrollo Web",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s",
      link: null,
      linkText: null,
      services: [
        {
          id: "web-design",
          title: "Diseño Web",
          description:
            "Websites premium que convierten visitantes en clientes. Diseño personalizado y optimizado para resultados.",
          link: "/websites",
          linkText: "Conoce más",
          icon: null,
        },
        {
          id: "web-development",
          title: "Desarrollo de Apps",
          description:
            "Aplicaciones web modernas y escalables que impulsan la eficiencia de tu negocio.",
          icon: null,
          link: null,
          linkText: null,
        },
        {
          id: "e-commerce",
          title: "E-commerce",
          description:
            "Optimización para buscadores que mejora tu visibilidad y atrae tráfico cualificado.",
          icon: null,
          link: null,
          linkText: null,
        },
      ],
    },
    {
      id: "marketing",
      icon: "/images/services/services-marketing.png",
      title: "Marketing",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s",
      link: null,
      linkText: null,
      services: [
        {
          id: "seo-audit",
          title: "Auditoria SEO",
          description:
            "Optimización para buscadores que mejora tu visibilidad y atrae tráfico cualificado.",
          link: null,
          linkText: null,
        },
        {
          id: "seo",
          title: "SEO",
          description:
            "Optimización para buscadores que mejora tu visibilidad y atrae tráfico cualificado.",
          link: null,
          linkText: null,
        },
        {
          id: "sem",
          title: "SEM",
          description:
            "Creación de campañas de publicidad en Google Ads, Facebook Ads o TikTok Ads.",
          link: null,
          linkText: null,
        },
      ],
    },
  ];

  return (
    <section className="bg-gradient-to-b from-brand-blue-dark to-brand-blue-reg w-full relative colmena-p pb-6 md:pb-10 colmena-rounded-b shadow-lg">
      <h2 className="colmena-title-h2 font-bold text-center mb-6 md:mb-10 text-brand-yellow tracking-tight">
        Nuestros Servicios
      </h2>

      <div className="max-w-screen-xl mx-auto relative flex flex-col gap-6 md:gap-10">
        {services.map((service) => (
          <ServiceGroup key={service.id} {...service} />
        ))}
      </div>
    </section>
  );
}

const ServiceGroup = ({
  id,
  icon,
  title,
  description,
  link,
  linkText,
  services,
}: {
  id: string;
  icon: string;
  title: string;
  description: string;
  link?: string | null;
  linkText?: string | null;
  services: {
    id: string;
    title: string;
    description: string;
    link?: string | null;
    linkText?: string | null;
    icon?: string | null;
  }[];
}) => {
  return (
    <div className="group colmena-rounded grid md:grid-cols-2 gap-12 px-6 py-8 lg:p-12 items-center bg-brand-beige text-brand-blue-mid relative overflow-hidden shadow-xl">
      <div className="flex flex-col gap-4 mb-4 items-center text-center relative z-1">
        <h3 className="text-brand-blue-mid text-3xl md:text-4xl lg:text-5xl">{title}</h3>
        <p className="text-brand-blue-mid">{description}</p>
        {link && linkText && (
          <Link
            href={link}
            className="text-blue-600 hover:text-blue-700 font-medium"
          >
            {linkText}
          </Link>
        )}
      </div>

      <div className="flex flex-col gap-4 relative z-1">
        {services.map((subService) => (
          <ServiceCard key={subService.id} {...subService} />
        ))}
      </div>
    </div>
  );
};

const ServiceCard = ({
  icon,
  title,
  description,
  link,
  linkText,
}: {
  icon?: string | null;
  title: string;
  description: string;
  link?: string | null;
  linkText?: string | null;
}) => {
  return (
    <div
      className="group rounded-2xl bg-white text-brand-blue-mid hover:bg-brand-blue-reg hover:text-white transition-all duration-300"
    >
      <div className="p-6 flex flex-col gap-y-1">
        <h3 className="text-xl font-bold">{title}</h3>
        <p>{description}</p>
        {link && linkText && (
          <Link
            href={link}
            className="mt-3 inline-flex items-center text-brand-blue-mid group-hover:text-white font-bold"
          >
            {linkText}
          </Link>
        )}
      </div>
    </div>
  );
};
