import { Pathnames, LocalePrefix } from 'next-intl/routing';

export const defaultLocale = 'es' as const;
export const locales = ['en', 'es'] as const;

export const pathnames: Pathnames<typeof locales> = {
  '/': '/',
  '/websites': {
    es: '/diseno-web',
    en: '/web-design',
  },
  '/about': {
    es: '/nosotros',
    en: '/about-us',
  },
  '/portfolio': {
    es: '/portafolio',
    en: '/portfolio',
  },
  '/contact': {
    es: '/contacto',
    en: '/contact',
  },
};

export const localePrefix: LocalePrefix<typeof locales> = 'always';

export const port = process.env.PORT || 3000;
export const host = process.env.NEXT_PUBLIC_SITE_URL
  ? `${process.env.NEXT_PUBLIC_SITE_URL}`
  : `http://localhost:${port}`;
