import React from "react";

interface VideoComponentProps {
  webmSrc: string;
  mp4Src: string;
  className?: string;
  controls?: boolean;
}

const VideoComponent: React.FC<VideoComponentProps> = ({ webmSrc, mp4Src, className, controls = false }) => {
  return (
    <div className={className} style={{ pointerEvents: 'none' }}>
      <video
        controls={controls}
        autoPlay
        loop
        muted
        playsInline
        style={{ pointerEvents: 'none' }}
      >
        <source src={webmSrc} type="video/webm" />
        <source src={mp4Src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoComponent;
