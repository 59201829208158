import { twMerge } from "tailwind-merge";
import { Link } from '@/navigation';

const SplashButton = ({ href, children, className, ...rest }: { href: string, children: any, className: string }) => {
  return (
    <Link
      href={href}
      className={
        twMerge(
          "rounded-4xl bg-brand-blue-reg px-8 py-2 text-white font-bold transition-all hover:scale-[1.02] active:scale-[0.98] hover:bg-brand-yellow hover:text-brand-blue-dark",
          className
        )
      }
      {...rest}
    >
      {children}
    </Link>
  );
};

export default SplashButton;